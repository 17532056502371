.text-brand {
  font-family: 'Roboto Mono', monospace;
}

.bg-indigo{
  background-color: #083D77;
}

.bg-blue{
  background-color: #66C3FF;
}

.text-indigo{
  background-color: transparent;
  color: #083D77;
}

.text-blue{
  background-color: transparent;
  color: #66C3FF;
}

.text-cultured{
  background-color: transparent;
  color: #F7F7F7;
}

.link-cultured{
  background-color: transparent;
  color: #F7F7F7;
}

.link-cultured:hover{
  background-color: transparent;
  color: #66C3FF;
}

.link-blue{
  background-color: transparent;
  color: #66C3FF;
}

.link-blue:hover{
  background-color: transparent;
  color: #083D77;
}

.btn-indigo{
  background-color: #083D77;
  color: #F7F7F7;
}

.btn-indigo:hover{
  background-color: #66C3FF;
  color: #292b2c;
}

.btn-light{
  background-color: #F7F7F7;
}

.btn-light:hover{
  background-color: #66C3FF;
}

.btn-brand{
  padding: 2rem;
}

.btn-brand:hover{
  padding: 1rem;
}